<template>
  <templatePage></templatePage>
</template>

<script>
import templatePage from "~/baseComponents/baseBooth/templatePage";
export default {
  components: { templatePage },
};
</script>

<style>
</style>